import type { SerializedStyles } from "@emotion/react";
import type { FunctionComponent } from "react";

import { StrapiFaqSection } from "@/types/strapi";

import type { SpacingStyleKey } from "@/tokens/configs/spacing_config";
import type { FontStyleSlug } from "@/tokens/configs/typography_config";

import { GridContainer } from "@/ui/atoms/grid_container";
import { Text } from "@/ui/atoms/text";
import { FaqList } from "@/ui/organisms/faq_list";

interface FaqSectionProps extends StrapiFaqSection {
    className?: SerializedStyles;
    headlineFontSize?: FontStyleSlug;
    marginBottom?: SpacingStyleKey;
    marginTop?: SpacingStyleKey;
}

export const FaqSection: FunctionComponent<FaqSectionProps> = ({
    headlineFontSize = "CompareSlugFaqHeadline",
    ...props
}) => {
    const _castedFaqProps = props!;

    return (
        <GridContainer
            marginBottom={_castedFaqProps.marginBottom}
            marginTop={_castedFaqProps.marginTop}
        >
            <Text
                columnSpan={{
                    extraSmall: "full",
                }}
                columnStart={{
                    extraSmall: 1,
                }}
                fontSize={headlineFontSize}
                tag="h2"
                textAlign="center"
            >
                {_castedFaqProps.Headline}
            </Text>

            <FaqList faqItems={_castedFaqProps.FAQ_Items} />
        </GridContainer>
    );
};
